import banner from "./shared-components/updates-banner/index.mjs";
import navBar from "./shared-components/nav-bar/index.mjs";
import footer from "./shared-components/custom-footer/index.mjs";

document.addEventListener('DOMContentLoaded', () => {
  const queryParams = new URLSearchParams(window.location.search);
  const errorMessage = decodeURIComponent('access_denied%20(Please%20subscribe%20for%20access%20to%20all%20our%20awesome%20online%20content!)');

  if (queryParams.get('error') === errorMessage) {
    showCustomPopup('Access Denied: Please subscribe for access to all our awesome online content!');
  }
});

document.getElementById('slide-over-close-button').addEventListener('click', function(event) {
  event.stopPropagation();
  addHiddenClass();
});

document.getElementById('slide-over-back').addEventListener('click', function(event) {
  event.stopPropagation();
  addHiddenClass();
});

function addHiddenClass() {
  document.getElementById('slide-over-panel').classList.add('hidden');
  document.getElementById('slide-over-back').classList.add('hidden');
}

document.getElementById('slide-over-link-1').addEventListener('click', function() {
  addHiddenClass();
});

document.getElementById('slide-over-link-2').addEventListener('click', function() {
  addHiddenClass();
});

document.getElementById('slide-over-link-3').addEventListener('click', function() {
  addHiddenClass();
});

document.getElementById('slide-over-link-4').addEventListener('click', function() {
  addHiddenClass();
});

document.getElementById('slide-over-link-5').addEventListener('click', function() {
  addHiddenClass();
});

document.getElementById('slide-over-link-6').addEventListener('click', function() {
  addHiddenClass();
});

document.getElementById('slide-over-link-7').addEventListener('click', function() {
  addHiddenClass();
});

document.getElementById('slide-over-link-8').addEventListener('click', function() {
  addHiddenClass();
});

document.getElementById('slide-over-link-9').addEventListener('click', function() {
  addHiddenClass();
});

document.getElementById('slide-over-link-10').addEventListener('click', function() {
  addHiddenClass();
});

document.getElementById('slide-over-link-11').addEventListener('click', function() {
  addHiddenClass();
});

document.getElementById('side-nav').addEventListener('click', function(event) {
  event.stopPropagation();
  document.getElementById('slide-over-panel').classList.remove('hidden');
  document.getElementById('slide-over-back').classList.remove('hidden');
});

function showCustomPopup(message) {
  const popupContainer = document.getElementById('custom-popup-container');
  const messageElement = popupContainer.querySelector('.popup-message');
  messageElement.textContent = message;
  popupContainer.style.display = 'flex';
  const closeButton = popupContainer.querySelector('.close-popup-btn');
  closeButton.addEventListener('click', () => {
    popupContainer.style.display = 'none';
  });
  popupContainer.addEventListener('click', (event) => {
    if (event.target === popupContainer) {
      popupContainer.style.display = 'none';
    }
  });
  document.querySelector('.popup-content').addEventListener('click', function(event) {
    event.stopPropagation();
  });
}

document.getElementById('faq-button-0').addEventListener('click', function() {
  document.getElementById('faq-0').classList.toggle('hidden');
  document.getElementById('faq-0-plus').classList.toggle('hidden');
  document.getElementById('faq-0-minus').classList.toggle('hidden');
});

document.getElementById('faq-button-1').addEventListener('click', function() {
  document.getElementById('faq-1').classList.toggle('hidden');
  document.getElementById('faq-1-plus').classList.toggle('hidden');
  document.getElementById('faq-1-minus').classList.toggle('hidden');
});

document.getElementById('faq-button-2').addEventListener('click', function() {
  document.getElementById('faq-2').classList.toggle('hidden');
  document.getElementById('faq-2-plus').classList.toggle('hidden');
  document.getElementById('faq-2-minus').classList.toggle('hidden');
});

document.getElementById('faq-button-3').addEventListener('click', function() {
  document.getElementById('faq-3').classList.toggle('hidden');
  document.getElementById('faq-3-plus').classList.toggle('hidden');
  document.getElementById('faq-3-minus').classList.toggle('hidden');
});

document.getElementById('faq-button-4').addEventListener('click', function() {
  document.getElementById('faq-4').classList.toggle('hidden');
  document.getElementById('faq-4-plus').classList.toggle('hidden');
  document.getElementById('faq-4-minus').classList.toggle('hidden');
});

document.getElementById('faq-button-5').addEventListener('click', function() {
  document.getElementById('faq-5').classList.toggle('hidden');
  document.getElementById('faq-5-plus').classList.toggle('hidden');
  document.getElementById('faq-5-minus').classList.toggle('hidden');
});

document.getElementById('contact-form').addEventListener('submit', function(event) {
  event.preventDefault();
  const formData = new FormData(this);

  fetch('/contact', {
    method: 'POST',
    body: new URLSearchParams(formData),
  })
    .then(response => {
      if (response.ok) {
        this.reset();
        alert('Your message has been sent successfully!');
      } else {
        alert('There was an error sending your message. Please try again later.');
      }
    })
    .catch(error => {
      alert(`There was a problem with the server. Please try again later.\n Error: ${error}`);
    });
});
